<template>
  <div>
    <div class="panel-address">
      <b-row>
        <b-col md="4" v-if="type !== 'shipping'">
          <InputSelect
            title="คำนำหน้าชื่อ"
            text="คำนำหน้าชื่อ"
            name="nameTitleId"
            valueField="id"
            textField="name"
            size="lg"
            v-bind:options="nameTitleArray"
            v-on:onDataChange="val => (form.nameTitleId = val)"
            v-model="form.nameTitleId"
            isRequired
            v-bind:isValidate="v.nameTitleId.$error"
            :v="v.nameTitleId"
            ref="nameTitleId"
            class="f-regular"
          />
        </b-col>
        <b-col md="4" v-if="type !== 'shipping'">
          <InputText
            v-model="form.firstname"
            :textFloat="titleFirstname"
            :placeholder="titleFirstname"
            type="text"
            name="nameOrCompanyName"
            size="lg"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            isRequired
            :isValidate="v.firstname.$error"
            :v="v.firstname"
            class="f-regular"
          />
        </b-col>

        <b-col md="4" v-if="type !== 'shipping'">
          <InputText
            v-model="form.lastname"
            :textFloat="titleLastname"
            :placeholder="titleLastname"
            type="text"
            name="lastname"
            size="lg"
            isRequired
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            :isValidate="v.lastname.$error"
            :v="v.lastname"
            class="f-regular"
          />
        </b-col>

        <b-col md="6">
          <InputText
            v-model="form.address"
            textFloat="ที่อยู่"
            placeholder="ที่อยู่"
            type="text"
            name="address"
            size="lg"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            isRequired
            :isValidate="v.address.$error"
            :v="v.address"
            class="f-regular"
          />
        </b-col>

        <b-col md="6">
          <InputText
            v-model="form.road"
            textFloat="ถนน"
            placeholder="ถนน"
            type="text"
            name="road"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            size="lg"
            :v="v.road"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputZipcode
            :itemsResults="resultsAddress"
            v-model="form.zipcode"
            textFloat="รหัสไปรษณีย์"
            placeholder="รหัสไปรษณีย์"
            type="tel"
            name="zipCode"
            isRequired
            :v="v.zipcode"
            :isValidate="v.zipcode.$error"
            :maxlength="5"
            :getAddress="getAddress"
            :setAddress="setAddress"
            :clearFields="clearFields"
            @onkeypress="isNumber"
            :isDisplay="isDisplay"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            size="lg"
            ref="zipCode"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.province"
            textFloat="จังหวัด"
            placeholder="จังหวัด"
            type="text"
            name="province"
            :isDisplay="true"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            size="lg"
            :v="v.province"
            isRequired
            :isValidate="v.province.$error"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.district"
            textFloat="อำเภอ"
            placeholder="อำเภอ"
            type="text"
            name="district"
            :isDisplay="true"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            size="lg"
            :v="v.district"
            isRequired
            :isValidate="v.district.$error"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.subdistrict"
            textFloat="ตำบล/แขวง"
            placeholder="ตำบล/แขวง"
            type="text"
            name="subdistrict"
            @onkeyup="
              handleAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            size="lg"
            :v="v.subdistrict"
            isRequired
            :isValidate="v.subdistrict.$error"
            class="f-regular"
          />
        </b-col>
        <b-col v-if="type == 'billing'" md="6">
          <InputText
            v-model="form.tax"
            textFloat="เลขประจำตัวผู้เสียภาษี"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            type="text"
            name="taxID"
            size="lg"
            isRequired
            :isValidate="v.tax.$error"
            :v="v.tax"
            :maxlength="13"
            @onkeypress="isNumber"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InputZipcode from "@/components/inputs/InputZipcode";
import { required, numeric, helpers } from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);

const address = value => {
  if (value.match(/^[ก-๙A-Za-z0-9'\\.\-\s\\,\\/]*$/)) {
    return true;
  } else {
    return false;
  }
};

const validateMaxLength = item => {
  console.log("item", item);
  if (item.length > 5) {
    return false;
  } else {
    return true;
  }
};

export default {
  name: "UserProfileAddressFormInput",
  components: {
    InputText,
    InputSelect,
    InputZipcode
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    handleAddress: {
      required: true,
      type: Function
    }
  },
  validations() {
    return {
      form: {
        firstname: {},
        lastname: {},
        address: {
          required,
          address
        },
        district: {
          required,
          alpha
        },
        subdistrict: {
          required,
          alpha
        },
        province: {
          required,
          alpha
        },
        zipcode: {
          required,
          numeric,
          validateMaxLength(item) {
            return validateMaxLength(item);
          }
        }
      }
    };
  },
  data() {
    return {
      nameTitleArray: [{ id: 0, name: "คำนำหน้าชื่อ" }],
      resultsAddress: []
    };
  },
  created: async function() {
    await this.getNameTitle();
  },
  computed: {
    titleFirstname() {
      return this.form.nameTitleId == 4 ? "ชื่อบริษัท" : "ชื่อ";
    },
    titleLastname() {
      return this.form.nameTitleId == 4 ? "สาขา" : "นามสกุล";
    },
    isDisplay() {
      return this.form.zipcode ? true : false;
    }
  },
  methods: {
    clearFields() {
      this.form.zipcode = "";
      this.form.district = "";
      this.form.province = "";
    },
    setAddress(selectedItem) {
      this.form.zipcode = selectedItem.zipcode;
      this.form.district = selectedItem.districtInfo.districtNameLocal;
      this.form.province = selectedItem.provinceInfo.provinceNameLocal;
      this.data.buyerInfo.shippingAddress.zipcodeExternalId = selectedItem.id;
    },
    getAddress: async function(zipcode) {
      await this.$axios
        .get(`${this.$baseUrl}/api/Transaction/address?zipcode=${zipcode}`)
        .then(async response => {
          if (response.data.result == 1) {
            this.resultsAddress = response.data.detail;
          }
        });
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getNameTitle: async function() {
      let nameTille = this.nameTitleArray;
      let title = null;
      await this.$axios
        .get(`${this.$baseUrl}/api/filter/NameTitle`)
        .then(async response => {
          if (response.data.result == 1) {
            title = response.data.detail;
            this.nameTitleArray = nameTille.concat(title);
          }
        });
    }
  }
};
</script>
