<template>
  <div class="autocomplete">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label :class="className">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <input
        @input="onChange"
        v-model="search"
        :class="['custom-input f-regular']"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :required="required"
        :value="value"
        :size="size"
        ref="input"
        @keypress="$emit('onkeypress', $event)"
        @keyup="$emit('onkeyup', $event.target.value)"
        :maxlength="maxlength"
        :disabled="isDisplay"
      />
      <font-awesome-icon
        v-if="isDisplay"
        icon="times"
        class="icon-times"
        size="lg"
        @click="clear()"
      />
      <ul
        id="autocomplete-results"
        v-show="isOpen"
        class="autocomplete-results ul-autocomplete"
      >
        <li v-if="itemsResults.length === 0" class="autocomplete-noresult">
          ไม่พบข้อมูล.
        </li>
        <li
          v-else
          v-for="(result, i) in itemsResults"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result"
        >
          {{ result.zipcode }} {{ result.districtInfo.districtNameLocal }}
          {{ result.provinceInfo.provinceNameLocal }}
        </li>
      </ul>
    </div>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        กรุณากรอกข้อมูลให้ครบถ้วน
      </span>
      <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกอย่างน้อย {{ v.$params.minLength.min }} อักษร</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกไม่เกิน {{ v.$params.maxLength.max }} อักษร</span
      >
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "InputZipcode",
  props: {
    itemsResults: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    },
    textFloat: {
      required: true,
      type: String
    },
    className: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    maxlength: {
      required: false,
      type: [Number, String]
    },
    getAddress: {
      required: false,
      type: Function
    },
    setAddress: {
      required: false,
      type: Function
    },
    clearFields: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      isOpen: false,
      search: this.value
    };
  },
  watch: {
    value(newValue) {
      this.search = newValue;
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    clear() {
      this.search = "";
      this.clearFields();
    },
    onChange() {
      this.getAddress(this.search);
      this.isOpen = true;
    },
    setResult(result) {
      this.search = result.zipcode;
      this.setAddress(result);
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
};
</script>

<style>
.autocomplete {
  margin-bottom: 15px;
  position: relative;
}
.input-custom {
  padding: 0px;
}
.input-custom > input:disabled {
  background-color: #f5f5f5;
}
.input-custom > label {
  color: #575757;

  font-size: 14px;
  margin-bottom: 2px;
}
.input-custom > input {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 5px 10px;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
.input-custom > input:focus {
  border: 1px solid #16274a;
}
.input-custom.error > input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.icon-times {
  position: absolute;
  top: 50%;
  left: 90%;
  cursor: pointer;
  z-index: 9999;
}

.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .input-custom > label {
    font-size: 15px;
  }
}
.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}

.ul-autocomplete {
  position: absolute;
  width: 100% !important;
  z-index: 9999;
  background-color: white;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}

.autocomplete-noresult {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
}
</style>
