<template>
  <div>
    <div>
      <div class="d-flex" v-if="orderSelected.statusId == 1">
        <div class="w-80">
          <InputText
            textFloat="คูปองโค้ด"
            v-model="orderSelected.promoCode"
            class="mr-2"
            placeholder="กรุณาใส่คูปองโค้ด"
            type="text"
            :isDisplay="isUseCoupon"
          />
        </div>

        <div class="mx-auto my-4">
          <b-button
            @click="onUseCoupon"
            :disabled="!orderSelected.promoCode"
            v-if="!isUseCoupon"
            class="text-white bg-color-primary text-nowrap"
            >ใช้คูปอง</b-button
          >
          <b-button
            @click="onRemoveCoupon"
            class="text-white bg-color-primary text-nowrap"
            v-else
            >ลบคูปอง</b-button
          >
        </div>
      </div>
      <div class="d-flex font-weight-bold" v-else>
        <div><span class="">คูปองโค้ด</span></div>
        <div class="ml-auto">
          <span class="">{{
            orderSelected.promoCode ? orderSelected.promoCode : "-"
          }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="d-flex font-weight-bold">
        <div><span class="">ราคารวม</span></div>
        <div class="ml-auto">
          <span class="">{{ orderSelected.total | numeral("0,0.00") }}</span>
        </div>
      </div>
      <div class="d-flex ml-3 mt-1">
        <div><span class="">ส่วนลด</span></div>
        <div class="ml-auto">
          <span v-if="orderSelected.discount > 0">- </span>
          <span class="">
            {{ orderSelected.discount | numeral("0,0.00") }}</span
          >
        </div>
      </div>
    </div>
    <div class="mt-1">
      <div class="d-flex font-weight-bold">
        <div><span class="">ค่าจัดส่งสุทธิ</span></div>
        <div class="ml-auto">
          <span
            v-if="
              orderSelected.shippingCost - orderSelected.discountShipping < 0
            "
            class=""
            >0</span
          >
          <span v-else class="">{{
            (orderSelected.shippingCost - orderSelected.discountShipping)
              | numeral("0,0.00")
          }}</span>
        </div>
      </div>
      <div class="d-flex ml-3 mt-1">
        <div><span class="">ค่าจัดส่ง</span></div>
        <div class="ml-auto">
          <span class="">{{
            orderSelected.shippingCost | numeral("0,0.00")
          }}</span>
        </div>
      </div>
      <div class="d-flex ml-3 mt-1">
        <div><span class="">ส่วนลด</span></div>
        <div class="ml-auto">
          <span v-if="orderSelected.discountShipping > 0">- </span>
          <span class="">
            {{ orderSelected.discountShipping | numeral("0,0.00") }}</span
          >
        </div>
      </div>
    </div>
    <div class="mt-1">
      <div class="d-flex font-weight-bold">
        <div><span class="">ราคาสุทธิ</span></div>
        <div class="ml-auto">
          <span class="">{{
            orderSelected.grandTotal | numeral("0,0.00")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText
  },
  props: {
    orderSelected: {
      required: true,
      type: Object
    },
    isUseCoupon: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {};
  },
  methods: {
    onUseCoupon() {
      if (this.orderSelected.promoCode) {
        this.$emit("onCalculatedPrice");
      }
    },
    onRemoveCoupon() {
      this.orderSelected.promoCode = "";
      this.$emit("onCalculatedPrice");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .input-custom > label {
  font-weight: bold;
}
::v-deep .input-custom > input {
  height: 37px;
}
.w-80 {
  width: 88%;
}
</style>
