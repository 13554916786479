<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="หลักฐานการโอนเงิน"
      hide-footer
      header-class=""
    >
      <div>
        <div class="content-modal">
          <b-row class="mt-3">
            <b-col>
              <b-form-group>
                <label>เลือกธนาคารปลายทาง </label>
                <b-form-radio
                  v-for="(item, index) in bankAccountLists"
                  :key="index + 'bankRadio'"
                  :value="item.id"
                  :id="index + 'bankRadio'"
                  name="bankAccountId"
                  v-model="orderTransferSlip.bankAccountId"
                  class="my-1 p-3 pl-5 border rounded"
                >
                  <img
                    class="mx-2 align-baseline"
                    :src="item.imageUrl"
                    style="width: 30px; height: 30px"
                  />
                  <div class="d-inline-block">
                    <div>{{ item.bankName }} ({{ item.accountNo }})</div>
                    <div class="text-small textc-primary">
                      {{ item.accountName }}
                    </div>
                  </div>
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <span class=""
                >หลักฐานการโอนเงิน
                <small v-if="isError" style="color: red"
                  >กรุณาเลือกรูปภาพ</small
                >
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(item, index) in orderTransferSlip.imageList"
              :key="index + 'dbfileimage'"
            >
              <b-row class="mb-2">
                <b-col>
                  <img
                    :src="item.imageUrl"
                    alt=""
                    style="width: 100%; height: 100%"
                  />
                </b-col>
                <b-col cols="6">
                  <div>
                    <span
                      >ชื่อไฟล์:
                      {{
                        item.imageUrl.substring(
                          item.imageUrl.lastIndexOf("/") + 1
                        )
                      }}
                    </span>
                    <font-awesome-icon
                      class="text-color-black"
                      icon="times"
                      @click="onEditImageFromDB(false, index)"
                    />
                  </div>
                  <div class="text-color-secondary text-small pointer">
                    <u @click="onEditImageFromDB(true, index)"
                      >แก้ไขหลักฐานการชำระเงิน</u
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              v-for="(item, index) in imageList"
              :key="index + 'fileimage'"
            >
              <b-row class="mt-2">
                <b-col cols="6">
                  <img :src="item" alt="" style="width: 100%; height: 100%" />
                </b-col>
                <b-col>
                  <div>
                    <span
                      >ชื่อไฟล์: {{ fileNameLists[index] }}
                      <font-awesome-icon
                        class="text-color-black pointer"
                        icon="times"
                        @click="onDeleteImageItem(index)"
                    /></span>
                  </div>
                  <div class="text-color-secondary text-small pointer">
                    <u>แก้ไขหลักฐานการชำระเงิน</u>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="6">
              <div
                class="
                  mx-1
                  bg-color-secondary
                  d-table-cell
                  align-middle
                  text-center
                  pointer
                "
                style="width: 150px; height: 150px; color: black"
                @click="clickImportImage(-1)"
              >
                <div>
                  <font-awesome-icon icon="plus-circle" size="lg" />
                  <div class="text-small">กดเพื่ออัพโหลดรูปภาพ</div>
                </div>
              </div>
              <input
                ref="inputImageFile"
                style="display: none"
                type="file"
                multiple
                @change="onFileChange"
                accept="image/x-png,image/gif,image/jpeg"
              />
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <div>
            <b-button class="btn-main-2" @click="hide">ยกเลิก</b-button>
          </div>
          <div class="ml-2">
            <b-button
              class="btn-main"
              :disabled="isDisable"
              @click="onUpdatedPayment"
              >ตกลง</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hide="hadleModalAlertClose"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import * as moment from "moment/moment";

export default {
  components: {
    ModalAlert,
    ModalAlertError,
    ModalLoading
  },
  props: {
    orderId: {
      required: true,
      type: Number
    },
    orderTotalAmount: {
      required: true,
      type: Number
    },
    orderStatus: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      modalShow: false,
      modalMessage: "",
      isDisable: false,
      bankAccountLists: [],
      orderTransferSlip: {
        amount: 0,
        bankAccountId: 0,
        imageList: [],
        transferDate: null,
        transferTime: null
      },
      imageIndex: -1,
      imageList: [],
      fileNameLists: [],
      imageIdLists: [],
      isError: false
    };
  },
  validations: {},
  methods: {
    hadleModalAlertClose() {
      console.log("hide");
      this.hide();
      this.$emit("onRefreshData");
    },
    onUpdatedPayment() {
      if (
        this.orderTransferSlip.imageList.length > 0 ||
        this.imageList.length > 0
      ) {
        this.isError = false;
        this.isDisable = true;
        this.$refs.modalLoading.show();
        var transferDate = moment().format("MM/DD/YYYY");
        var transferTime = moment().format("HH:mm");
        this.$axios
          .post(this.$baseUrl + "/api/payment/updateSlipInfo", {
            Amount: this.orderTotalAmount,
            transactionid: this.orderId,
            transferDate: transferDate,
            transferTime: transferTime,
            bankAccountId: this.orderTransferSlip.bankAccountId,
            UploadImage: this.imageList,
            deleteImage: this.imageIdLists
          })
          .then(response => {
            if (response.data.result === 1) {
              if (this.orderStatus == 2) {
                this.onChangeStatus();
              } else {
                this.isDisable = false;
                this.modalMessage = "อัพโหลดหลักฐานการชำระเงินสำเร็จ";
                this.$refs.modalLoading.hide();
                this.$refs.modalAlert.show();
              }
            } else {
              this.modalMessage = response.data.message;
              this.$refs.modalLoading.hide();
              this.$refs.modalAlertError.show();
            }
          });
      } else {
        this.isError = true;
      }
    },
    onChangeStatus: async function() {
      let body = (body = {
        orderId: this.orderId,
        statusId: 3,
        reStock: false
      });
      await this.$axios
        .post(this.$baseUrl + "/api/transaction/authorizedstatus", body)
        .then(response => {
          this.isDisable = false;
          if (response.data.result == 1) {
            this.modalMessage = "อัพโหลดหลักฐานการชำระเงินสำเร็จ";
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = response.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    onDeleteImageItem(index) {
      this.imageList.splice(index, 1);
      this.fileNameLists.splice(this.imageIndex, 1);
    },
    onEditImageFromDB(editFlag, index) {
      if (editFlag) {
        this.clickImportImage(-1);
        this.imageIdLists.push(this.orderTransferSlip.imageList[index].id);
      } else {
        this.imageIdLists.push(this.orderTransferSlip.imageList[index].id);
        this.orderTransferSlip.imageList.splice(index, 1);
      }
    },
    clickImportImage(index) {
      this.$refs.inputImageFile.click();
      this.imageIndex = index;
    },
    toBase64(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.imageList.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    onFileChange(e) {
      if (e.target.files.length > 0) {
        const file = e.target.files;
        if (this.imageIndex > -1) {
          this.imageList.splice(this.imageIndex, 1);
          this.fileNameLists.splice(this.imageIndex, 1);
        }
        Array.from(file).forEach(element => {
          this.toBase64(element);
          this.fileNameLists.push(element.name);
        });
        this.imageIdLists.forEach(element => {
          var indexTarget = this.orderTransferSlip.imageList.findIndex(
            item => item.id === element
          );
          this.orderTransferSlip.imageList.splice(indexTarget, 1);
        });
      }
    },
    getBanks: async function() {
      this.$axios
        .get(this.$baseUrl + "/api/payment/bankaccount", {
          params: { transactionId: this.orderId }
        })
        .then(response => {
          if (response.data.result === 1) {
            this.bankAccountLists = response.data.detail;
          }
        });
    },
    getUploadSlip: async function() {
      this.$axios
        .get(
          this.$baseUrl + "/api/transaction/" + this.orderId + "/transferSlip"
        )
        .then(response => {
          if (response.data.result === 1) {
            this.orderTransferSlip = response.data.detail;
            if (this.orderTransferSlip.bankAccountId == 0) {
              this.orderTransferSlip.bankAccountId = this.bankAccountLists[0].id;
            }
          }
        });
    },
    show: async function() {
      await this.getBanks();
      await this.getUploadSlip();
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    }
  }
};
</script>

<style scoped>
.text-error {
  color: red;
}
.content-modal {
  height: 600px;
  overflow: auto;
}
::v-deep .custom-control-input {
  left: 24px;
  top: 26px;
}
::v-deep
  .custom-control
  .custom-control-input:checked
  ~ .custom-control-label::before,
::v-deep .custom-radio .custom-control-label::before,
::v-deep .custom-control .custom-control-label::after {
  top: 12px !important;
}
::v-deep .custom-control-label {
  cursor: pointer;
}
</style>
